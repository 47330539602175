
import '../stylesheets/home.scss'

const terminal = () => {
  const node = document.querySelector(`[data-api="timestamp"]`);
  setInterval(() => {
    node.textContent = Math.floor(Date.now() / 1000);
  }, 1000);

  ['EUR', 'JPY', 'GBP', 'CAD'].forEach(currency => {
    const node = document.querySelector(`[data-api="current_${currency}"]`);
    const deviation = window.terminal_data[currency] * 0.001;
    setInterval(() => {
      node.textContent = ((Math.random() * (2 * deviation)) + window.terminal_data[currency] - deviation).toFixed(10);
    }, 250);
  });
};

const main = () => {
  terminal();
};

document.addEventListener("DOMContentLoaded", () => {
  main();
});
